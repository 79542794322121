<script lang="ts">
  // Import components
  import SideNav from "./SideNav.svelte"
  import MenuButton from "./MenuButton.svelte"

  // Import store
  import { mainMarginLeft } from "./store"

  // vars
  let username = ""
  let email = ""
  let password = ""
  let url = "https://api.dev.wpaio.one/user/register"
  let registerFailed = false
  let failureMessage = ""
  let registerSuccess = false
  let successMessage = ""

  interface RegistrationSuccess {
    ok: number
    data: { message: string; userId: string }
  }

  interface RegistrationFail {
    ok: number
    data: { message: string }
  }
  async function registerUser() {
    const postJson = { username, email, password }
    const rawResponse = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postJson),
    })
    if (rawResponse.status === 500) {
      registerFailed = true
      const failedRegistration = (await rawResponse.json()) as RegistrationFail
      failureMessage = failedRegistration.data.message
    } else {
      const content = (await rawResponse.json()) as RegistrationSuccess
      registerSuccess = true
      successMessage = "We sent you a confirmation link to your email address."
    }
  }
</script>

<main>
  <SideNav />
  <div class="main" style="margin-left: {$mainMarginLeft}">
    <MenuButton />
    {#if registerFailed}
      <div class="errorBox">{failureMessage}</div>
    {/if}
    {#if registerSuccess}
      <div class="successBox">{successMessage}</div>
    {/if}
    <h1>Sign Up</h1>
    <div class="form-group">
      <input type="text" placeholder="Username" bind:value={username} />
      <input type="text" placeholder="Email" bind:value={email} />
      <input type="password" placeholder="Password" bind:value={password} />
    </div>
    <button on:click={registerUser}>Sign Up!</button>
  </div>
</main>

<style>
  input {
    margin: auto;
    display: block;
  }

  main {
    text-align: center;
    padding: 1em;
    margin: 0 auto;
  }

  h1 {
    color: #ff3e00;
    font-size: 4em;
    font-weight: 100;
  }
</style>
