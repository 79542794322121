<script lang="ts">
  // Import store
  import { mainMarginLeft, sideNavWidth } from "./store"

  // Import deps
  import Fa from "svelte-fa"
  import { faBars } from "@fortawesome/free-solid-svg-icons"

  function openNav() {
    $sideNavWidth = "250px"
    $mainMarginLeft = "250px"
  }
  function closeNav() {
    $sideNavWidth = "0"
    $mainMarginLeft = "0"
  }
  function toggleNav() {
    if ($sideNavWidth == "0") {
      openNav()
    } else {
      closeNav()
    }
  }
</script>

<div class="menuButton">
  <button class="menuButton" on:click={toggleNav}><Fa icon={faBars} /></button>
</div>

<style>
  .menuButton {
    float: right;
  }
</style>
