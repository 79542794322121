<script lang="ts">
  // Import store
  import { mainMarginLeft, sideNavWidth, userData } from "./store"
  function closeNav() {
    $sideNavWidth = "0"
    $mainMarginLeft = "0"
  }
</script>

<div id="mySidenav" class="sidenav" style="width: {$sideNavWidth}">
  <a href="javascript:void(0)" class="closebtn" on:click={closeNav}>&times;</a>
  <a href="#/">Home</a>
  {#if $userData.loggedIn}
    <a href="#/profile">Profile</a>
  {:else}
    <a href="#/login">Sign in/Sign up</a>
  {/if}
</div>

<style>
  /* The side navigation menu */
  .sidenav {
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 1; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    background-color: #111; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 60px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  }

  /* The navigation menu links */
  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
    background-image: none;
  }

  /* When you mouse over the navigation links, change their color */
  .sidenav a:hover {
    color: #f1f1f1;
  }

  /* Position and style the close button (top right corner) */
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  /* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
  @media screen and (max-height: 450px) {
    .sidenav {
      padding-top: 15px;
    }
    .sidenav a {
      font-size: 18px;
    }
  }
</style>
